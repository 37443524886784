"use client";
import { useRouter, useSearchParams } from "next/navigation";
import { Typography } from "@mui/material";

import { useStore, observer } from "../../../../service/mobx";
import ChipFilter from "../../../Chip/Filter";

function SearchFilterChip({ entityName, label, href, Icon }) {
  const { analytics, search } = useStore();
  const searchParams = useSearchParams();
  const router = useRouter();
  const selected = search.filterType === label;

  return (
    <ChipFilter
      selected={selected}
      label={label}
      StartIcon={selected ? undefined : Icon}
      endIcon={<Count type={label} />}
      onClick={() => {
        const toggled = !selected;
        const newParams = { type: toggled ? [href] : undefined };

        if (search.userInput === "") {
          newParams.q = undefined;
        } else if (searchParams.get("q") !== search.userInput) {
          newParams.q = [search.userInput];
        }

        search.updateUrlParams(router, newParams);
        analytics.track.event("Search Filter", {
          entity: { type: entityName, value: toggled }
        });
      }}
    />
  );
}
export default observer(SearchFilterChip);
const Count = observer(function Count({ type }) {
  const { utilities, search } = useStore();
  const hide = search.loadingCount;
  const count = search.count[type];

  return count === undefined ? null : (
    <Typography
      variant="labelSm"
      color="secondary.on.container"
      bgcolor="secondary.container"
      sx={theme => ({
        ...(hide
          ? { fontSize: 0, opacity: 0 }
          : { p: 0.5, py: 0.1, mr: -1, ml: 0.85 }),
        borderRadius: theme.shape.round,
        transition: theme.transitions.create("all")
      })}
    >
      {utilities.formatNumber(count)}
    </Typography>
  );
});
